import React, { useEffect, useState } from "react";
import "./catalog.css";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Category, Filter } from "../../../reducers/main/main-api";
import { Chip, Grid, useMediaQuery, useTheme } from "@mui/material";

const Index = () => {
  const dispatch = useDispatch();
  const { categorys, filter } = useSelector((store) => store.main);


  const [activePath, setActivePath] = useState(null);
  const [{ height, width }, setHouseParams] = useState({
    height: null,
    width: null,
  });
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("md"));
  const [type, setType] = useState("");
  useEffect(() => {
    dispatch(Category());
    if (filter.length === 0) {
      dispatch(Filter(type));
    }
  }, []);
  const handleRect = React.useCallback((node) => {
    setHouseParams({
      height: node?.clientHeight - 24,
      width: node?.clientWidth - 24,
    });
  }, []);
  const getFilter = async (item) => {
    await dispatch(Filter(item));
    setType(item);
  };
  return (
    <section id={"CatalogPage"}>
      {categorys?.length && (
        <div className="categories-container">
          {smUp && (
            <HouseSVG height={height} width={width} activePath={activePath} />
          )}
          <div className="categories-block">
            <Grid container spacing={3} ref={handleRect}>
              {categorys.map((item, index) => (
                <Grid item lg={6} xs={12} key={item.title + index}>
                  <NavLink to={`/catalog/${item.id}`} className={"btn"}>
                    <Chip
                      size="large"
                      label={item.title}
                      onClick={() => getFilter(item.title)}
                      onMouseEnter={() => setActivePath(item.title)}
                      onMouseLeave={() => setActivePath(null)}
                      sx={{
                        width: "100%",
                        height: "100%",
                        p: 0,
                        borderRadius: "66px",
                        "& .MuiChip-label": {
                          color:
                            item.title === type ? "#000!important" : "#fff",
                        },
                        backgroundColor:
                          item.title === type ? "#FFD15B" : "#383A44",
                        "&:hover": {
                          background: "#FEC841",
                        },
                      }}
                    />
                  </NavLink>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </section>
  );
};

export default Index;

const HouseSVG = ({ activePath, height, width }) => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const PATH = {
    Короны_KR: "Короны KR",
    Замковые_камни_ZK: "Замковые камни ZK",
    Арка: "Арка",
    Подоконники_NP: "Подоконники NP",
    Угловые_Камни_UK: "Угловые Камни UK",
    Пояс_KR: "Пояс KR",
    "Лицевой_И_Угловой_Капитель_L;_LP": "Лицевой И Угловой Капитель L; LP",
    "Нижние_Ставки NV": "Нижние Ставки NV",
    Прямые_наличники_PN: "Прямые наличники PN",
    Пилястра_KN: "Пилястра KN",
    Декоративные_камни: "Декоративные камни",
    Kарнизы_KN: "Kарнизы KN",
    Готовые_комплекты_Окна: "Готовые комплекты Окна",
    Термопанели_Саймалуу_Таш: "Термопанели Саймалуу Таш",
    "Термопанели_Сары-Таш": "Термопанели Сары-Таш",
    Арпен: "Арпен",
    Угловые_русты: "Угловые русты",
    Угловые_профили_NP: "Угловые профили NP",
    "Колонна_«Ионик»": "Колонна «Ионик»",
    Капители_погонным_метром_L: "Капители погонным метром L",
  };
  return (
    <svg
      width={lgUp ? "20%" : "30%"}
      viewBox="0 0 596 2134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M206.885 1519.27V1758.03H208.385V1519.27H215.5L214.189 1758.03H167V1519.27H174.115V1758.03H175.615V1519.27H180.669V1758.03H182.169V1519.27H185.912V1758.03H187.412V1519.27H193.777V1758.03H195.277V1519.27H199.02V1758.03H200.52V1519.27H206.885Z"
        fill={activePath === PATH.Прямые_наличники_PN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154 1419H0V1425.83H4.96774H5.71774V1426.58V1459.16H10.8414V1426.58V1425.83H11.5914H18.2151H18.9651V1426.58V1459.16H24.0887V1426.58V1425.83H24.8387H31.4624H32.2124V1426.58V1459.16H37.336V1426.58V1425.83H38.086H44.7097H45.4597V1426.58V1459.16H50.5833V1426.58V1425.83H51.3333H57.957H58.707V1426.58V1459.16H63.8306V1426.58V1425.83H64.5806H72.8602H73.6102V1426.58V1459.16H78.7339V1426.58V1425.83H79.4839H86.1075H86.8575V1426.58V1459.16H91.9812V1426.58V1425.83H92.7312H99.3548H100.105V1426.58V1459.16H105.228V1426.58V1425.83H105.978H112.602H113.352V1426.58V1459.16H118.476V1426.58V1425.83H119.226H125.849H126.599V1426.58V1459.16H131.723V1426.58V1425.83H132.473H139.097H139.847V1426.58V1459.16H144.97V1426.58V1425.83H145.72H154V1419ZM154 1427.33H146.47V1459.91V1460.66H145.72H139.097H138.347V1459.91V1427.33H133.223V1459.91V1460.66H132.473H125.849H125.099V1459.91V1427.33H119.976V1459.91V1460.66H119.226H112.602H111.852V1459.91V1427.33H106.728V1459.91V1460.66H105.978H99.3548H98.6048V1459.91V1427.33H93.4812V1459.91V1460.66H92.7312H86.1075H85.3575V1459.91V1427.33H80.2339V1459.91V1460.66H79.4839H72.8602H72.1102V1459.91V1427.33H65.3306V1459.91V1460.66H64.5806H57.957H57.207V1459.91V1427.33H52.0833V1459.91V1460.66H51.3333H44.7097H43.9597V1459.91V1427.33H38.836V1459.91V1460.66H38.086H31.4624H30.7124V1459.91V1427.33H25.5887V1459.91V1460.66H24.8387H18.2151H17.4651V1459.91V1427.33H12.3414V1459.91V1460.66H11.5914H4.96774H4.21774V1459.91V1427.33H0V1469H154V1427.33Z"
        fill="#D9D9D9"
      />
      <path
        d="M151.01 898V567H75.5049H56.6287H54.2189V572.005H8.26993V567H4.71906H0V898H151.01Z"
        fill="#D9D9D9"
      />
      <path d="M57.0807 582.229H0V649.377H57.0807V582.229Z" fill="#0E6583" />
      <path
        d="M135.237 582.229H79.9131V649.376H135.237V582.229Z"
        fill="#0E6583"
      />
      <path
        d="M135.237 835.248H79.9131V885.851H135.237V835.248Z"
        fill="#0E6583"
      />
      <path d="M57.0807 835.248H0V885.851H57.0807V835.248Z" fill="#0E6583" />
      <path
        d="M135.237 664.947H79.9131V819.677H135.237V664.947Z"
        fill="#0E6583"
      />
      <path d="M57.0807 664.947H0V819.677H57.0807V664.947Z" fill="#0E6583" />
      <path
        d="M151.01 1810V1479H75.5049H56.6287H54.2189H11H8.26993H4.71906H0V1810H151.01Z"
        fill="#D9D9D9"
      />
      <path d="M57.0807 1494.23H0V1561.38H57.0807V1494.23Z" fill="#0E6583" />
      <path
        d="M135.237 1494.23H79.9131V1561.38H135.237V1494.23Z"
        fill="#0E6583"
      />
      <path
        d="M135.237 1747.25H79.9131V1797.85H135.237V1747.25Z"
        fill="#0E6583"
      />
      <path d="M57.0807 1747.25H0V1797.85H57.0807V1747.25Z" fill="#0E6583" />
      <path
        d="M135.237 1576.95H79.9131V1731.68H135.237V1576.95Z"
        fill="#0E6583"
      />
      <path d="M57.0807 1576.95H0V1731.68H57.0807V1576.95Z" fill="#0E6583" />

      <path
        d="M218.887 1492.25H164.547V1506.02H218.887V1492.25Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M165.524 1784.94H219.864V1771.17H165.524V1784.94Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M221.905 1509.08H161.528V1515.2H221.905V1509.08Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M163.17 1768.12H223.547V1762H163.17V1768.12Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M226.434 1418.78H157V1469.29H226.434V1418.78Z"
        fill={"#D9D9D9"}
      />
      <path
        d="M226.434 1472.35H157V1483.06H160.019V1487.66H221.905V1483.06H226.434V1472.35Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M157 1804.81H226.434V1794.1H223.415V1789.51H161.528V1794.1H157V1804.81Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M226.434 1809H157V1815.12H226.434V1809Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />

      <path
        d="M253.766 1382.05H0V1389.7H253.766V1382.05Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M245.684 1392.76H0V1405.01H240.835V1398.89H245.684V1392.76Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M239.219 1414.19V1408.07H0V1414.19H239.219Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M260.231 1371.34H0V1378.99H260.231V1371.34Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M240.912 1830.49H0V1836.61H240.912V1830.49Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M237.783 1839.67H0V1851.92H233.09V1845.79H237.783V1839.67Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M228.397 1861.1V1854.98H0V1861.1H228.397Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M247.169 1818.25H0V1825.9H247.169V1818.25Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />

      <path
        d="M79.1138 2006.5H0V2044.76H79.1138V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M79.1138 2095H0V2133.26H79.1138V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M162.881 2006.5H82.2161V2044.76H162.881V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M162.881 2095H82.2161V2133.26H162.881V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M246.649 2006.5H165.984V2044.76H246.649V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M246.649 2095H165.984V2133.26H246.649V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M330.417 2006.5H249.751V2044.76H330.417V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M330.417 2095H249.751V2133.26H330.417V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M414.184 2006.5H333.519V2044.76H414.184V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M414.184 2095H333.519V2133.26H414.184V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M497.952 2006.5H417.287V2044.76H497.952V2006.5Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M497.952 2095H417.287V2133.26H497.952V2095Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M497.952 2050.88H460.722V2089.15H497.952V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M457.619 2050.88H376.954V2089.15H457.619V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M373.852 2050.88H293.187V2089.15H373.852V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M290.084 2050.88H209.419V2089.15H290.084V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M206.316 2050.88H125.651V2089.15H206.316V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M122.549 2050.88H41.8838V2089.15H122.549V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M38.7813 2050.88H0V2089.15H38.7813V2050.88Z"
        fill={activePath === PATH.Декоративные_камни ? "#F6D528" : "#D9D9D9"}
      />

      <path
        d="M458.519 1430.67H451.988V1806.03H458.519V1430.67Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M458.519 1400.03H416.067V1839.74H458.899V1829.01H427.497V1409.22H458.519V1400.03Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M458.519 1415.35H438.926H435.66V1822.88H458.899V1812.16H445.457V1424.54H458.519V1415.35Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M458.519 1381.65H396.474V1856.59H458.899V1844.33H407.903V1393.9H458.519V1381.65Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M458.519 1317.3H323V1924H458.519V1885.7H365.452V1352.54H458.519V1317.3Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M376.881 1363.26H458.519V1377.05H391.576V1861.18H458.519V1874.97H376.881V1363.26Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M445.703 477.519V962.691H334.676V477.519H445.703ZM352.636 494.885H351.636V495.885V950.447V951.447H352.636H429.376H430.376V950.447V495.885V494.885H429.376H352.636ZM353.636 949.447V496.885H428.376V949.447H353.636Z"
        fill={activePath === PATH.Пилястра_KN ? "#F6D528" : "#D9D9D9"}
      />

      <path
        d="M446.028 967.282H335V1017.79H446.028V967.282Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M312 1039.22V1020.85H467.112V1039.22H312Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M298 1090V1042H484V1090H298Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M494.981 1097H0V1121.26H511V1112.16L494.981 1097Z"
        fill="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.192871 1127.07H514.279V1198.36H0.192871V1181.53H498.363H499.363V1180.53V1141.92V1140.92H498.363H0.192871V1127.07ZM0.192871 1179.53V1142.92H497.363V1179.53H0.192871Z"
        fill="#D9D9D9"
      />
      <path
        d="M511.077 1202.77H0V1228H495.106V1220.58L511.077 1208.71V1202.77Z"
        fill="#D9D9D9"
      />

      <path
        d="M484 1243H298V1259.84H302.895V1264.43H479.105V1259.84H484V1243Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M474.541 1268.79H308V1293.28H474.541V1268.79Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M308 2001H474.54V1972H308V2001Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M469.745 1296.34H313V1303.99H469.745V1296.34Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M313.26 1969H470V1958H313.26V1969Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M323 1311.65V1307.06H458.519V1311.65H323Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M458.519 1950V1954.59H323V1950H458.519Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M460 1930V1935H322V1930H460Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M296 1240V1235H486V1240H296Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />

      <path
        d="M15.5349 332H4.27222V345.775H15.5349V332Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M52.4866 345.895V332.121H68.5761V345.895H52.4866Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M107.191 345.895V332.121H123.28V345.895H107.191Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M161.895 345.895V332.121H177.985V345.895H161.895Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M216.599 345.895V332.121H232.689V345.895H216.599Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M271.304 345.895V332.121H287.393V345.895H271.304Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M326.008 345.895V332.121H342.098V345.895H326.008Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M390.366 345.895V332.121H406.456V345.895H390.366Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M451.506 345.895V332.121H467.596V345.895H451.506Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M485.294 321.407V362.731H1V354H21.5962V324H1V321.407H485.294ZM443.462 324.468V353.548H475.641V324.468H443.462ZM382.321 353.548V324.468H414.5V353.548H382.321ZM44.4417 324.468V353.548H76.6207V324.468H44.4417ZM99.1459 353.548V324.468H131.325V353.548H99.1459ZM153.85 324.468V353.548H186.029V324.468H153.85ZM208.555 353.548V324.468H240.734V353.548H208.555ZM263.259 324.468V353.548H295.438V324.468H263.259ZM317.963 353.548V324.468H350.142V353.548H317.963Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 289.266H512.647V295.388H504.602L504.602 294.638H1.00001V296.138H504.602L504.602 303.041H496.557V310.693H492.294V309.943H1.00001V311.443H491.73V318.346H1V303.791H496.557V302.291H1V289.266Z"
        fill={activePath === PATH.Пояс_KR ? "#F6D528" : "#D9D9D9"}
      />

      <path
        d="M217.515 920.244H0V926.366H217.515V920.244Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M214.69 929.427H0V941.671H210.452V935.549H214.69V929.427Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M206.215 950.854V944.732H0V950.854H206.215Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M224.992 908H0V915.653H224.992V908Z"
        fill={activePath === PATH.Подоконники_NP ? "#F6D528" : "#D9D9D9"}
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.01185 486.702H0.550049V494.355H4.74267L4.01185 486.702ZM57.9322 494.355L58.663 486.702H239.164V494.355H57.9322Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.03501 497.416H0.550049V505.068H5.76583L5.03501 497.416ZM56.909 505.068L57.6398 497.416H233.236V505.068H56.909Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2476 551H0V557H10.8206L10.2476 551ZM51.8538 557L52.4268 551H217.419V557H51.8538Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.74487 541.8H0V547.922H9.33094L8.74487 541.8ZM52.3931 547.922L52.9792 541.8H227.352V547.922H52.3931Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.86576 533H0V539.122H8.45184L7.86576 533ZM53.2722 539.122L53.8583 533H219.879V539.122H53.2722Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.98665 523.434H0V529.556H7.57272L6.98665 523.434ZM54.1513 529.556L54.7374 523.434H227.352V529.556H54.1513Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.32726 508.129H0V520.373H7.49657L6.32726 508.129ZM55.7165 520.373L56.8858 508.129H225.276V514.251H219.714V520.373H55.7165Z"
        fill={activePath === PATH.Kарнизы_KN ? "#F6D528" : "#D9D9D9"}
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.55249 479H56.399L49.4437 561H13.5078L6.55249 479ZM12.3488 485.083H11.5325L11.6015 485.897L17.3976 554.23L17.4558 554.917H18.1449H44.807H45.4961L45.5543 554.23L51.3504 485.897L51.4194 485.083H50.6031H12.3488ZM18.834 553.417L13.1651 486.583H49.7868L44.1179 553.417H18.834Z"
        fill={activePath === PATH.Замковые_камни_ZK ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M162 614V849.963H210.503V614H162ZM176.265 628.126H175.265V629.126V833.325V834.325H176.265H196.237H197.237V833.325V629.126V628.126H196.237H176.265ZM177.265 832.325V630.126H195.237V832.325H177.265Z"
        fill={activePath === PATH.Прямые_наличники_PN ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M479 367H301V383H479V367Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M454 448H326V462H454V448Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M463.581 386H315L326.429 398.244H455.417L463.581 386Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M334 445.378V400.994H445.028V445.378H334Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M334.676 472.928V465.275H445.703V472.928H334.676Z"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
      <path
        d="M506.79 143.868L1.19385 0V33.6712L506.79 177.539H595.917V143.868H506.79Z"
        fill="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.19385 41.3237L498.687 186.722H576.471V195.905H569.989L569.989 195.155H502.035L1.403 49.7867L1.19385 50.5069V41.3237ZM1.19385 51.2879V64.2815L1.40221 63.561L498.794 207.399H563.507L563.507 208.149H569.989L569.989 196.655H501.928H501.822L501.719 196.626L1.19385 51.2879ZM1.19385 71.9342V65.0622L498.479 208.87L498.581 208.899H498.687H563.507L563.507 215.802H557.025L557.025 215.052H502.034L1.40096 71.2133L1.19385 71.9342ZM1.19385 72.7145V81.117L498.687 226.515H557.025L557.025 216.552H501.928H501.823L501.721 216.523L1.19385 72.7145Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M49.8555 117.244L54.717 105L70.922 109.592L66.0605 121.836L49.8555 117.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M1 103.5V89L11.5975 92.5915L6.73598 104.836L1 103.5Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M119.649 138.244L124.51 126L140.715 130.592L135.854 142.836L119.649 138.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M185.08 157.244L189.942 145L206.147 149.592L201.285 161.836L185.08 157.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M257.233 179.07L262.094 166.826L278.299 171.417L273.438 183.661L257.233 179.07Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M321.178 196.244L326.039 184L342.244 188.592L337.383 200.836L321.178 196.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M391.843 218.244L396.705 206L412.91 210.592L408.048 222.836L391.843 218.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M463.382 240.244L468.243 228L484.448 232.592L479.587 244.836L463.382 240.244Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.19385 119.38V111.727L10.9168 114.788L21.0578 88.7915L48.4735 96.7847L38.4654 122.441L70.8754 133.154L79.55 105.845L118.667 117.25L109.767 143.868L142.177 154.582L151.037 126.688L183.325 136.101L174.587 162.234L206.997 172.948L216.12 145.663L256.873 157.544L245.889 183.661L278.299 194.375L289.411 167.031L319.921 175.926L310.709 202.028L343.119 212.741L352.275 185.359L391.428 196.774L382.011 223.455L414.421 234.168L423.771 206.204L462.934 217.622L453.313 244.882L485.723 255.595L495.268 227.049L498.687 228.046H542.441V283.144H501.928L1.19385 134.685V119.38Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        d="M544.062 278.553V228.046L560.267 236.923V269.88L544.062 278.553Z"
        fill={activePath === PATH.Короны_KR ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M162 901.976V853H210.51V901.976H162ZM186.5 894C195.613 894 203 886.613 203 877.5C203 868.387 195.613 861 186.5 861C177.387 861 170 868.387 170 877.5C170 886.613 177.387 894 186.5 894ZM186.5 895C196.165 895 204 887.165 204 877.5C204 867.835 196.165 860 186.5 860C176.835 860 169 867.835 169 877.5C169 887.165 176.835 895 186.5 895ZM186.5 889C192.851 889 198 883.851 198 877.5C198 871.149 192.851 866 186.5 866C180.149 866 175 871.149 175 877.5C175 883.851 180.149 889 186.5 889Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M162 610.976V562H210.51V610.976H162ZM186.5 603C195.613 603 203 595.613 203 586.5C203 577.387 195.613 570 186.5 570C177.387 570 170 577.387 170 586.5C170 595.613 177.387 603 186.5 603ZM186.5 604C196.165 604 204 596.165 204 586.5C204 576.835 196.165 569 186.5 569C176.835 569 169 576.835 169 586.5C169 596.165 176.835 604 186.5 604ZM186.5 598C192.851 598 198 592.851 198 586.5C198 580.149 192.851 575 186.5 575C180.149 575 175 580.149 175 586.5C175 592.851 180.149 598 186.5 598Z"
        fill={activePath === PATH["Нижние_Ставки NV"] ? "#F6D528" : "#D9D9D9"}
      />
      <rect
        x="325"
        y="1938"
        width="131"
        height="9"
        fill={
          activePath === PATH["Лицевой_И_Угловой_Капитель_L;_LP"]
            ? "#F6D528"
            : "#D9D9D9"
        }
      />
    </svg>
  );
};
